<template>
  <v-dialog v-model="isOpen" content-class="rounded-xl px-3" max-width="400px" width="100%" v-bind="$attrs">
    <template #activator="{props: activatorProps}">
      <div v-bind="activatorProps">
        <slot name="activator" />
      </div>
    </template>
    <v-card class="rounded-xl">
      <v-overlay absolute :value="isLoading">
        <v-progress-circular
          style="position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%)"
          indeterminate
          color="var(--theme-icone)"
        />
      </v-overlay>
      <v-card-title class="d-flex justify-end px-2 pt-2 pb-0">
        <div class="close" @click="closeModale()">
          <icon-close class="bouton_icon" type="icon" filled style="font-size: 35px" />
        </div>
      </v-card-title>

      <v-card-text class="d-flex flex-column justify-center">
        <slot name="content" />
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script setup lang="ts">
// Data
const isOpen = ref(false);

// Props
defineProps({
  width: {
    type: String,
    required: false,
    default: '400px',
  },
  isLoading: {
    required: false,
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(['close', 'open']);

// Methods
const closeModale = function () {
  emit('close');
  isOpen.value = false;
};

const openModale = function () {
  emit('open');
  isOpen.value = true;
};

defineExpose({
  closeModale,
  openModale,
  isOpen,
});
</script>

<style scoped>
.close {
  position: absolute;
}

.close:hover {
  cursor: pointer;
}

.bouton_icon {
  stroke: var(--theme-bouton) !important;
  width: 35px;
}
</style>
